import { useState } from 'react';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { ModalProps } from '@typings';
import { deleteSecret } from '@services/secret.services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { invariant, noop, toastifyResponseError } from '@utils';

import { Button, Icon, Modal } from '@components';

type Props = ModalProps & {
  keyName: string;
  getSecrets: () => Promise<void>;
};

export const DeleteSecretModal = ({
  keyName: key,
  getSecrets,
  closeModal = noop,
}: Props) => {
  const { secretsUrl } = useSelector(clusterContextSelector)!;
  const { projectName, organizationName } = useSelector(contextNamesSelector);

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      invariant(projectName);

      await deleteSecret({
        secretsUrl,
        key,
        projectName,
        organizationName,
      });
      await getSecrets();

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Delete Secret" className="w-[520px]">
      <p className="mt-2 flex flex-wrap items-center justify-center text-neural-03">
        Are you sure you want to delete
        <span className="space-both inline-flex items-center gap-1 text-body text-black">
          <Icon icon={faLockKeyhole} className="h-4 w-4 text-[14px]" />
          {key}
        </span>
        secret?
      </p>

      <Modal.Footer>
        <Button variant="ghost" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={loading} className="px-14" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { toast } from '@features';
import { getOrganizationSettings, updateOrganizationDefaults } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { invariant, normalizeFormErrors, toastifyResponseError } from '@utils';

import { Button, ChaseSpinner, Field, Helmet } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  credits: z.string().optional(),
});

export const OrganizationGeneralSettingsOutlet = () => {
  const { adminUrl } = useSelector(configSelector);
  const { organizationName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
  });

  const [initializing, setInitializing] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  useEffect(() => {
    const run = async () => {
      try {
        invariant(organizationName);

        const { userDefaultCredits } = await getOrganizationSettings({
          adminUrl,
          organizationName,
        });

        reset({ credits: userDefaultCredits });
        setInitializing(false);
      } catch (error) {
        toastifyResponseError(error);
      }
    };

    if (organizationName) {
      run();
    }
  }, [adminUrl, organizationName, reset]);

  const handleSettingsSubmit = handleSubmit(async ({ credits }) => {
    setLoading(true);

    try {
      await updateOrganizationDefaults({ adminUrl, organizationName, credits });

      toast.success(
        `The settings for the ${organizationName} organization have been updated`,
      );
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  const renderContent = () => {
    if (initializing) {
      return (
        <div className="relative flex min-h-[200px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <div className="mt-4 flex flex-col gap-6">
        <Field.Input
          {...register('credits')}
          label="Default Credits"
          type="number"
          note="The default amount of credits that will be given to each new user added to the organization. Empty value means unlimited default credits"
          error={errors.credits}
        />
        <div className="flex justify-end">
          <Button type="submit" loading={loading} className="px-10">
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('General', 'Organization Settings', '%o')} />
      <form
        className="relative max-w-[560px] rounded-xl bg-white p-10"
        onSubmit={handleSettingsSubmit}
      >
        <h6 className="text-h6">General</h6>
        <div className="relative">{renderContent()}</div>
      </form>
    </>
  );
};

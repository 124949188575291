import { createSelector } from 'reselect';

import { State } from '@typings';
import { isObjectEmpty } from '@utils';

export const userSelector = (state: State) => state.user;

export const isAuthorizedSelector = createSelector(
  userSelector,
  (user) => !isObjectEmpty(user),
);

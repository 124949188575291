import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import { faCircleNodes } from '@fortawesome/pro-solid-svg-icons';

import { Billing, BillingInterface, OutletContext } from '@typings';
import { organizationCreditsSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';
import { isString, truncateDecimals } from '@utils';

import { Button, Icon, Modal, Render, Table } from '@components';
import { BuyCreditsModal } from '@components/Modals';

export const BillingCreditsPage = () => {
  const { organizationCredits, isOrganizationBalanceUnlimited } = useSelector(
    organizationCreditsSelector,
  );

  const {
    params: { monthToDateUsage },
    setTabTitle,
  } = useOutletContext<OutletContext.Billing>();

  const { isOrganizationManager } = useTenantRole();

  const canBuyCredits =
    isOrganizationManager && !isOrganizationBalanceUnlimited;

  useEffect(() => {
    setTabTitle(Billing.Tab.Credits);
  }, [setTabTitle]);

  const balance = isString(organizationCredits)
    ? truncateDecimals(organizationCredits)
    : 'unlimited';

  const makeRow = ({ clusterName, usage }: BillingInterface.WithoutProject) => {
    const currentMonth = dayjs.utc().month();
    const credits = usage.find(({ date }) => {
      const month = dayjs.unix(date).month();

      return currentMonth === month;
    })?.credits;

    return (
      <Table.Row key={clusterName}>
        <Table.Cell className="flex flex-row items-center justify-start gap-1 border-b border-l border-t-0 border-neural-02 py-2">
          <Icon icon={faCircleNodes} className="text-neural-04" />
          {clusterName}
        </Table.Cell>
        <Table.Cell className="truncate border-b border-r border-t-0 border-neural-02 py-2 text-right">
          {credits ?? '-'}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div className="flex flex-col gap-6 px-10 py-6">
      <div className="flex flex-col gap-1">
        <p className="text-neural-04">Available</p>
        <p className="text-h5 capitalize text-black">{balance}</p>
      </div>
      <Render if={monthToDateUsage}>
        <div className="flex flex-col gap-1">
          <p className="text-neural-04">Month-to-date total usage</p>
          <Table className="max-w-xs auto-cols-auto grid-cols-[auto,min-content] overflow-auto border-neural-02">
            <Table.Body className="[&>tr:first-of-type>td:first-child]:rounded-tl-lg [&>tr:first-of-type>td]:border-t [&>tr:last-of-type>td:first-child]:rounded-bl-lg [&>tr:last-of-type>td:last-child]:rounded-r-lg">
              {monthToDateUsage.map(makeRow)}
            </Table.Body>
          </Table>
        </div>
      </Render>
      <Modal
        disabled={!canBuyCredits}
        content={<BuyCreditsModal balance={organizationCredits} />}
      >
        <Button
          disabled={!canBuyCredits}
          className="w-min truncate px-10 capitalize"
        >
          Buy credits
        </Button>
      </Modal>
    </div>
  );
};

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';

import { NO_ORGANIZATION } from '@constants';
import { toast } from '@features';
import { contextNamesSelector, organizationCreditsSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';
import { truncateDecimals } from '@utils';
import { compareBalances } from '@utils/helpers/compareBalances';

import { ChaseSpinner, Icon, Modal } from '@components';
import { BuyCreditsModal } from '@components/Modals';

import { UserPanelButton } from './UserPanelButton';

export const UserPanelCredits = () => {
  const { organizationName } = useSelector(contextNamesSelector);
  const {
    organizationCredits,
    userOrganizationCredits,
    organizationCreditsLoading,
    isOrganizationBalanceUnlimited,
  } = useSelector(organizationCreditsSelector);

  const [searchParams] = useSearchParams();
  const { isOrganizationManager } = useTenantRole();

  const isNoOrganization = organizationName === NO_ORGANIZATION;
  const isUserBalance =
    !isNoOrganization &&
    compareBalances(organizationCredits, userOrganizationCredits);
  const balance = isUserBalance ? userOrganizationCredits : organizationCredits;

  const canBuyCredits =
    isOrganizationManager &&
    !isNoOrganization &&
    !isOrganizationBalanceUnlimited;
  const buyCreditsIconValue = canBuyCredits ? undefined : null;

  useEffect(() => {
    const buyCreditsStatus = searchParams.get('buy-credits-status');
    const credits = searchParams.get('credits');

    if (!buyCreditsStatus) {
      return;
    }

    if (buyCreditsStatus === 'success' && credits) {
      toast.success(
        `The purchase of ${credits} credits was successful. Credits will be added shortly`,
      );
    }

    if (buyCreditsStatus === 'failure') {
      toast.error('Something went wrong when buying credits');
    }
  }, [searchParams]);

  const normalizeCreditsBalance = (balance: `${number}` | null | undefined) =>
    balance ? truncateDecimals(balance) : 'unlimited';

  const getFormattedBalance = () => {
    const value = isNoOrganization ? null : balance;

    return normalizeCreditsBalance(value);
  };

  if (organizationCreditsLoading) {
    return (
      <div className="px-3">
        <ChaseSpinner color="secondary" className="h-5 w-5" />
      </div>
    );
  }

  return (
    <Modal
      disabled={!canBuyCredits}
      content={<BuyCreditsModal balance={balance} />}
    >
      <UserPanelButton
        icon={buyCreditsIconValue}
        disabled={!canBuyCredits}
        className="flex min-w-[180px] items-center gap-2 text-white"
      >
        <Icon icon={faCoins} className="h-10 w-10 rounded-md bg-success-dark" />
        <div className="min-w-0 flex-1 text-left text-white">
          <p className="text-body-small text-neural-03">Credits</p>
          <p className="truncate capitalize">{getFormattedBalance()}</p>
        </div>
      </UserPanelButton>
    </Modal>
  );
};

import { isNumber } from '@utils';

/**
 * `undefined` for unlimited balance
 */
type Balance = `${number}` | undefined;

/**
 * Compares organization and user balance
 * to define is user balance needed to use
 *
 * `true` is to use user balance
 */
export const compareBalances = (
  organizationBalance: Balance,
  userBalance: Balance,
): boolean => {
  const isOrganizationBalanceUnlimited = !isNumber(organizationBalance);
  const isUserBalanceUnlimited = !isNumber(organizationBalance);

  if (isOrganizationBalanceUnlimited) {
    return true;
  }

  if (isUserBalanceUnlimited) {
    return false;
  }

  return Number(organizationBalance) < Number(userBalance);
};

import { useContext, useEffect, useRef, useState } from 'react';

import { cn } from '@utils';
import { appConstructorNavigation } from '@content';

import { Button } from '@components';
import { AppConstructorNavigationContext } from '@components/Providers';

type AppConstructorNavigation = keyof typeof appConstructorNavigation;

export const JobConstructorNavigator = () => {
  const { navigation } = useContext(AppConstructorNavigationContext);

  const sectionsRef = useRef<NodeListOf<Element>>();
  const [activeName, setActiveName] = useState<AppConstructorNavigation>(
    navigation[0],
  );

  useEffect(() => {
    if (navigation.length) {
      setActiveName(navigation[0]);
    }
  }, [navigation]);

  useEffect(() => {
    if (!navigation.length) {
      return;
    }

    const anchorsSelector = navigation.map((name) => `#${name}`).join(',');
    const sections = document.querySelectorAll(anchorsSelector);

    if (sections) {
      sectionsRef.current = sections;
    }
  }, [navigation]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionsRef.current;

      if (!sections) {
        return;
      }

      const element = Array.from(sections).find((element) => {
        const { top, bottom } = element.getBoundingClientRect();

        return bottom > 0 && top < window.innerHeight;
      });

      if (element) {
        const name = element.getAttribute('id') as AppConstructorNavigation;

        setActiveName(name);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigatorClick = (name: AppConstructorNavigation) => {
    const SCROLL_TO_TIMEOUT = 450;
    const HEADER_OFFSET = -200;
    const top =
      document.getElementById(name)!.getBoundingClientRect().top +
      window.pageYOffset +
      HEADER_OFFSET;

    window.scrollTo({ top, behavior: 'smooth' });

    setTimeout(() => {
      setActiveName(name);
    }, SCROLL_TO_TIMEOUT);
  };

  const makeNavigationItem = (
    name: AppConstructorNavigation,
    index: number,
  ) => {
    const isActive = name === activeName;
    const { title } = appConstructorNavigation[name];

    return (
      <Button
        theme
        key={name}
        className={cn(
          'group flex items-center gap-3 text-neural-04 transition-colors hover:text-black',
          { '!text-black': isActive },
        )}
        onClick={() => handleNavigatorClick(name as AppConstructorNavigation)}
      >
        <span
          className={cn(
            'flex h-9 w-9 items-center justify-center rounded-full bg-neural-02 font-600 transition-colors',
            { '!bg-primary !text-white': isActive },
          )}
        >
          {index + 1}
        </span>
        <span className="text-h6">{title}</span>
      </Button>
    );
  };

  return (
    <div className="relative">
      <div className="sticky left-10 top-36 flex flex-col gap-10">
        {navigation.map(makeNavigationItem)}
      </div>
    </div>
  );
};

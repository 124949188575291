import { App, Job, Mode } from '@typings';
import { DEDICATED_APP_NAME, PATH } from '@constants';
import { ensureArray } from '@utils';

import * as jobImage from '@images/jobs';

import { sidebarItems } from './shared.content';

const builtIntAppsOptions = {
  jobs: {
    description:
      'A tool enabling the execution of containerized workloads, seamlessly integrated with other apps and cluster resources',
  },
  images: {
    description:
      'A UI for the container image registry available within the cluster',
  },
  files: {
    description:
      'A file manager facilitating access to the underlying network storage within the cluster',
  },
  disks: {
    description:
      'An app for managing block storage, streamlining the configuration, allocation, and maintenance of persistent disks',
  },
  secrets: {
    description:
      'A secure secret store integrated with other apps, ensuring safe management and usage of credentials and keys',
  },
  buckets: {
    description:
      'An object storage service that enables the creation of buckets, credential acquisition, and efficient management of stored objects',
  },
  flows: {
    description:
      'A workflow engine designed to execute jobs organized in Directed Acyclic Graphs (DAGs) with integrated data caching for enhanced efficiency',
  },
};

export const builtItApps: App.BuiltInItem[] = sidebarItems
  .filter(({ path }) => ensureArray(path)[0] !== PATH.APPS)
  .map(({ solidIcon, name, path }) => {
    const formattedName = name.toLowerCase();
    /**
     * todo: resovle type warning
     */
    // @ts-ignore
    const options = builtIntAppsOptions[
      formattedName
    ] as Partial<App.BuiltInItem>;

    return {
      ...options,
      icon: solidIcon,
      name,
      path: ensureArray(path)[0],
    };
  });

const jupiterArgs =
  '--no-browser --ip=0.0.0.0 --port 8888 --allow-root --NotebookApp.token= --notebook-dir=/var/storage --NotebookApp.shutdown_no_activity_timeout=7200 --MappingKernelManager.cull_idle_timeout=7200 --MappingKernelManager.cull_connected=True';
export const dedicatedApps: Job.Item[] = [
  {
    title: 'Terminal',
    name: 'shell',
    description:
      'A web-based remote shell instrumented with Apolo CLI, providing immediate access to dedicated compute resources',
    image: 'ghcr.io/neuro-inc/web-shell:pipelines',
    httpPort: 7681,
    httpAuth: true,
    env: {
      NEURO_EVENT_CATEGORY: 'WEB-CLI',
      NEURO_CLI_DISABLE_PYPI_VERSION_CHECK: 'yes',
      WORKDIR: '/var/storage',
      TERM: 'xterm-256color',
      LANG: 'C.utf8',
    },
    jobNamePrefix: 'shell-',
    jobResourcePreset: 'cpu-small',
    defaultVolumes: [
      {
        storagePath: 'storage:',
        jobPath: '/var/storage',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    editableVolumes: true,
    editableSecrets: true,
  },
  {
    title: 'LLM Inference',
    name: DEDICATED_APP_NAME.LLM_INFERENCE,
    description:
      'A highly-available LLM inference service with an OpenAI-compatible API, capable of efficiently serving both standard and quantized models available on HuggingFace',
    logo: jobImage.llmInference,
    image: 'ghcr.io/neuro-inc/app-deployment',
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.LLM_INFERENCE}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'PostgreSQL',
    name: DEDICATED_APP_NAME.POSTGRESQL,
    description:
      'An industry-standard relational database system that includes pgvector for advanced semantic search capabilities',
    logo: jobImage.postgresql,
    image: 'ghcr.io/neuro-inc/app-deployment',
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.POSTGRESQL}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Text Embeddings Inference',
    name: DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE,
    description:
      'Comprehensive toolkit designed for efficient deployment and serving of open source text embeddings models. It enables high-performance extraction for the most popular models',
    logo: jobImage.textEmbeddingsInference,
    image: 'ghcr.io/neuro-inc/app-deployment',
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'PrivateGPT',
    name: DEDICATED_APP_NAME.PRIVATE_GPT,
    logo: jobImage.privateGpt,
    description:
      'A production-ready AI project that allows you to ask questions about your documents using the power of Large Language Models (LLMs). 100% private, no data leaves your execution environment at any point',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.PRIVATE_GPT}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Dify',
    name: DEDICATED_APP_NAME.DIFY,
    description:
      'Open-source LLM app development platform. Orchestrate LLM apps from agents to complex AI workflows, with an RAG engine',
    image: 'ghcr.io/neuro-inc/app-deployment',
    logo: jobImage.dify,
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.DIFY}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Stable Diffusion',
    name: DEDICATED_APP_NAME.STABLE_DIFFUSION,
    logo: jobImage.stableDiffusion,
    description:
      'An open-source platform for generating and integrating advanced image generation into your projects. Build and orchestrate creative applications from interactive interfaces to powerful API-driven solutions, all powered by cutting-edge diffusion models',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    env: {},
    jobNamePrefix: `${DEDICATED_APP_NAME.STABLE_DIFFUSION}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Fooocus',
    name: DEDICATED_APP_NAME.FOCUS,
    logo: jobImage.fooocus,
    description:
      'Free, offline, open-source image generator that creates images from prompts without manual tweaking',
    image: 'ghcr.io/neuro-inc/app-deployment:latest',
    jobNamePrefix: `${DEDICATED_APP_NAME.FOCUS}-`,
    defaultVolumes: [],
    editableVolumes: false,
    editableSecrets: false,
  },
  {
    title: 'Jupyter Notebook',
    name: 'jupyter',
    logo: jobImage.jupyter,
    description:
      'An interactive tool that enables the creation and sharing of documents with live code, visualizations, and narrative text',
    image: 'ghcr.io/neuro-inc/base:pipelines',
    httpPort: 8888,
    httpAuth: true,
    env: {
      NEURO_CLI_DISABLE_PYPI_VERSION_CHECK: 'yes',
    },
    jobNamePrefix: 'jupyter-',
    jobResourcePreset: 'gpu-small',
    shm: true,
    jobLifeSpan: null,
    defaultVolumes: [
      {
        storagePath: 'storage:',
        jobPath: '/var/storage',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    command: `bash -c "(rsync -a --ignore-existing /var/notebooks/README.ipynb /var/storage/) && (jupyter notebook ${jupiterArgs} --NotebookApp.default_url=/notebooks/README.ipynb)"`,
    editableVolumes: true,
    editableSecrets: true,
    group: 'IDEs',
    mlflowIntegration: true,
  },
  {
    title: 'Jupyter Lab',
    name: 'jupyter-lab',
    logo: jobImage.jupyter,
    description:
      'An interactive development environment designed for managing notebooks, code, and data, enabling seamless creation and sharing of dynamic documents',
    image: 'ghcr.io/neuro-inc/base:pipelines',
    httpPort: 8888,
    httpAuth: true,
    env: {
      NEURO_CLI_DISABLE_PYPI_VERSION_CHECK: 'yes',
    },
    jobNamePrefix: 'jupyter-lab-',
    jobResourcePreset: 'gpu-small',
    shm: true,
    jobLifeSpan: null,
    defaultVolumes: [
      {
        storagePath: 'storage:',
        jobPath: '/var/storage',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    command: `bash -c "(rsync -a --ignore-existing /var/notebooks/README.ipynb /var/storage/) && (jupyter lab ${jupiterArgs} --NotebookApp.default_url=/lab/tree/README.ipynb)"`,
    editableVolumes: true,
    editableSecrets: true,
    group: 'IDEs',
    mlflowIntegration: true,
  },
  {
    title: 'VS Code',
    name: 'vscode',
    logo: jobImage.vscode,
    image: 'ghcr.io/neuro-inc/code-server:pipelines',
    description:
      'A lightweight, powerful source code editor with a rich ecosystem of extensions for many languages and runtimes',
    httpPort: 8080,
    httpAuth: true,
    env: {},
    jobNamePrefix: 'vscode-',
    jobResourcePreset: 'cpu-small',
    defaultVolumes: [
      {
        storagePath: 'storage:',
        jobPath: '/var/storage',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    command: '--auth=none --disable-telemetry /var/storage',
    // Workaround for https://github.com/neuro-inc/platform-api/issues/1096
    // We re-write entrypoint with "sudo" so that vscode gets started as root,
    // see https://github.com/cdr/code-server/blob/a942531/ci/release-image/Dockerfile#L43  # noqa
    entryPoint:
      'sudo dumb-init fixuid -q /usr/bin/code-server --host 0.0.0.0 .',
    editableVolumes: true,
    editableSecrets: true,
    mlflowIntegration: true,
  },
  {
    title: 'PyCharm Community Edition',
    name: 'pycharm',
    image: 'ghcr.io/neuro-inc/projector-pycharm-c:pipelines',
    logo: jobImage.pycharm,
    description: 'A Python IDE for data science and web development',
    httpPort: 8887,
    httpAuth: true,
    env: {},
    jobNamePrefix: 'pycharm-',
    jobResourcePreset: 'cpu-small',
    defaultVolumes: [
      {
        storagePath: 'storage:',
        jobPath: '/var/storage',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    editableVolumes: true,
    editableSecrets: true,
    mlflowIntegration: true,
  },
  {
    title: 'ML Flow',
    name: 'mlflow',
    logo: jobImage.mlflow,
    description:
      'A tool that streamlines the full lifecycle of machine learning projects, enhancing manageability, traceability, and reproducibility',
    image: 'ghcr.io/neuro-inc/mlflow:pipelines',
    tags: ['application', 'application:mlflow'],
    httpPort: 80,
    httpAuth: true,
    command:
      'server --host 0.0.0.0 --port 80 --backend-store-uri=sqlite:////db/mlflow.db --artifacts-destination=/mlruns --serve-artifacts',
    env: {},
    jobNamePrefix: 'mlflow-',
    jobResourcePreset: 'cpu-small',
    defaultVolumes: [
      {
        storagePath: 'disk:global-mlflow-db',
        jobPath: '/db',
        mode: Mode.ReadWrite,
        editable: false,
        createDiskIfMissing: true,
        nonRemovable: true,
        defaultDiskSizeGB: 1,
      },
      {
        storagePath: 'storage:global/mlflow/mlruns',
        jobPath: '/mlruns',
        mode: Mode.ReadWrite,
        editable: true,
        nonRemovable: true,
      },
    ],
    editableVolumes: true,
    editableSecrets: false,
  },
  {
    title: 'Apolo Deploy',
    name: 'in_job_deployment',
    image: 'ghcr.io/neuro-inc/job-deploy-app:pipelines',
    logo: jobImage.inJobDeployment,
    description:
      'A simple model deployment service leveraging Triton and MLflow as its core inference servers',
    httpPort: 8501,
    httpAuth: true,
    env: {
      MLFLOW_TRACKING_URI: 'CHANGE_ME',
      TRITON_MODEL_REPO: '/tmp/triton_model_repo',
      TRITON_MODEL_REPO_STORAGE: 'storage:global/triton_model_repo',
    },
    jobNamePrefix: 'deploy-app-job-',
    jobResourcePreset: 'cpu-small',
    defaultVolumes: [
      {
        storagePath: 'storage:global/triton_model_repo',
        jobPath: '/tmp/triton_model_repo',
        mode: Mode.ReadWrite,
        editable: false,
      },
    ],
    editableVolumes: false,
    editableSecrets: true,
    mlflowIntegration: true,
  },
];

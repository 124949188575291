import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';
import clsx from 'clsx';

import { Billing, BillingUsage } from '@typings';
import { NO_ORGANIZATION, PATH } from '@constants';
import { getBilling } from '@services';
import { contextNamesSelector, userClustersSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { as, capitalize } from '@utils';
import { defaultBillingParams } from '@content';

import { ChaseSpinner, Helmet, Render, Theme } from '@components';
import { BillingHandleCenter, BillingTabs } from '@components/Billing';
import { Layout } from '@components/Layouts';

export const BillingLayout = () => {
  const { organizationName } = useSelector(contextNamesSelector);
  const userClusters = useSelector(userClustersSelector);

  const { makeTitle } = useHelmetTitle();
  const { pathname } = useLocation();

  const [tabTitle, setTabTitle] = useState<Billing.Tab>();
  /**
   * Tracking initialization to hide billing handle center
   */
  const [initialized, setInitialization] = useState(false);
  const [fetchingBilling, setFetchingBilling] = useState(false);
  const [billing, setBilling] = useState<Billing.State>({
    withProject: [],
    withoutProject: [],
  });
  const [params, setParams] = useImmer<Billing.Params>(defaultBillingParams);

  const isCreditsPage = pathname === PATH.BILLING_CREDITS;
  /**
   * Prevents extending page until end of a page
   */
  const isShrinkedPage = [PATH.BILLING_CREDITS].includes(pathname);
  const organizationClusters = useMemo(
    () =>
      userClusters
        .filter(
          ({ orgName }) => as(orgName, NO_ORGANIZATION) === organizationName,
        )
        .map(({ clusterName }) => clusterName),
    [organizationName, userClusters],
  );

  const getGroups = useCallback(
    (groups: Billing.Group[], withProject: boolean) => {
      const set = new Set(groups);

      if (withProject) {
        set.add(Billing.Group.Project);
      } else {
        set.delete(Billing.Group.Project);
      }

      return Array.from(set);
    },
    [],
  );

  const normalizeBillingResponse = useCallback(
    <Usage extends BillingUsage.WithProject | BillingUsage.WithoutProject>(
      billings: PromiseSettledResult<unknown>[],
    ) =>
      billings
        .filter(
          (
            billing,
          ): billing is PromiseFulfilledResult<Billing.Response<Usage>> =>
            billing.status === 'fulfilled',
        )
        .map(({ value }) => ({
          clusterName: value.clusterName,
          usage: value.usage,
        })),
    [],
  );

  useEffect(() => {
    /**
     * Gets clusters month to date total usage for credits page
     */
    const run = async () => {
      try {
        const billing = normalizeBillingResponse<BillingUsage.WithoutProject>(
          await Promise.allSettled(
            organizationClusters.map((clusterName) =>
              getBilling<BillingUsage.WithoutProject>({
                clusterName,
                organizationName,
                groups: [Billing.Group.Month],
                startDate: defaultBillingParams.startDate,
                endDate: defaultBillingParams.endDate,
              }),
            ),
          ),
        );

        setParams((params) => {
          params.monthToDateUsage = billing;
        });
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    run();
  }, [
    organizationName,
    organizationClusters,
    setParams,
    normalizeBillingResponse,
  ]);

  useEffect(() => {
    /**
     * Grouping by "project" makes difference in billing response data.
     * Due to that, there are kinds of duplications in requests and
     * keeping it in paralleled `withProject` and `withoutProject` state
     */
    const run = async () => {
      const WITH_PROJECT = true;

      try {
        setFetchingBilling(true);

        const billingWithProject =
          normalizeBillingResponse<BillingUsage.WithProject>(
            await Promise.allSettled(
              organizationClusters.map((clusterName) =>
                getBilling<BillingUsage.WithProject>({
                  clusterName,
                  organizationName,
                  groups: getGroups(params.groups, WITH_PROJECT),
                  startDate: params.startDate,
                  endDate: params.endDate,
                }),
              ),
            ),
          );
        const billingWithoutProject =
          normalizeBillingResponse<BillingUsage.WithoutProject>(
            await Promise.allSettled(
              organizationClusters.map((clusterName) =>
                getBilling({
                  clusterName,
                  organizationName,
                  groups: getGroups(params.groups, !WITH_PROJECT),
                  startDate: params.startDate,
                  endDate: params.endDate,
                }),
              ),
            ),
          );

        setBilling({
          withProject: billingWithProject,
          withoutProject: billingWithoutProject,
        });
        setInitialization(true);
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setFetchingBilling(false);
      }
    };

    run();
  }, [
    organizationName,
    params.startDate,
    params.endDate,
    params.groups,
    organizationClusters,
    getGroups,
    normalizeBillingResponse,
  ]);

  const handleTabTitleChange = useCallback((tab: Billing.Tab) => {
    setTabTitle(capitalize(tab) as Billing.Tab);
  }, []);

  const renderContent = () => {
    if (!initialized) {
      return (
        <Layout.Content className="relative flex justify-center">
          <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />
        </Layout.Content>
      );
    }

    return (
      <Layout.Content className="flex flex-col">
        <BillingHandleCenter
          isCenterDisabled={isCreditsPage}
          billing={billing}
          params={params}
          setParams={setParams}
        />
        <Theme.Container
          className={clsx('relative flex flex-col p-0', {
            'flex-1': !isShrinkedPage,
          })}
        >
          <BillingTabs />
          <Render if={fetchingBilling}>
            <div
              className={clsx('mt-40 flex items-center justify-center', {
                'mb-40': isShrinkedPage,
              })}
            >
              <ChaseSpinner color="black" className="h-14 w-14" />
            </div>
          </Render>
          <Render if={!fetchingBilling}>
            <Outlet
              context={{ params, billing, setTabTitle: handleTabTitleChange }}
            />
          </Render>
        </Theme.Container>
      </Layout.Content>
    );
  };

  return (
    <Layout
      title={makeTitle(tabTitle, 'Billing', '%o')}
      showHeaderFeatures={false}
      isProjectReffered={false}
      clusterContextTracker={false}
      projectContextTracker={false}
    >
      <Helmet
        title={makeTitle('Billing', 'Organization', '%o')}
        description="Take full control of your billing by monitoring resource usage and spending. Get in-depth cost breakdowns and efficiently manage your account credits to stay on top of your expenses"
      />
      {renderContent()}
    </Layout>
  );
};

import { FiltersChange } from '@hooks';
import { capitalize } from '@utils';

import { Field } from '@components';

const STATUSES = {
  ALL: 'all',
  FAILED: 'failed',
  SUCCESS: 'succeeded',
  PENDING: 'pending',
  RUNNING: 'running',
  CANCELLED: 'cancelled',
};

type Props = {
  search: string;
  filters: {
    search: string;
    status: string;
  };
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterChange: FiltersChange;
};

export const JobFilters = ({
  search,
  filters: { status },
  onSearchChange,
  onFilterChange,
}: Props) => {
  const statusOptions = Object.values(STATUSES).map((status) => ({
    value: status,
    text: capitalize(status),
  }));

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onFilterChange('status', event.target.value);
  };

  return (
    <>
      <Field.Input
        label="Search"
        containerClassName="flex-1"
        className="border-neural-03"
        value={search}
        onChange={onSearchChange}
      />
      <Field.Select
        className="w-[140px] border-neural-03"
        value={status}
        options={statusOptions}
        onChange={handleStatusChange}
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { TenantRole } from '@typings';
import { toast } from '@features';
import {
  getClusterOrganizationSettings,
  updateClusterOrganizationDefaults,
} from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import {
  capitalize,
  invariant,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, ChaseSpinner, Field, Helmet } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  role: z.nativeEnum(TenantRole),
  jobs: z.string().optional(),
});

export const ClusterGeneralSettingsOutlet = () => {
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
  });

  const [initializing, setInitializing] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  useEffect(() => {
    const run = async () => {
      try {
        invariant(clusterName);

        const { defaultRole, defaultQuota: { totalRunningJobs } = {} } =
          await getClusterOrganizationSettings({
            adminUrl,
            clusterName,
            organizationName,
          });

        const values = {
          role: defaultRole,
          jobs: totalRunningJobs,
        };

        setInitializing(false);
        reset(values);
      } catch (error) {
        toastifyResponseError(error);
      }
    };

    if (clusterName) {
      run();
    }
  }, [adminUrl, clusterName, organizationName, reset]);

  const roleOptions = Object.values(TenantRole).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  const handleSettingsSubmit = handleSubmit(async ({ credits, role, jobs }) => {
    setLoading(true);

    try {
      await updateClusterOrganizationDefaults({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        credits,
        role,
        jobs,
      });

      toast.success(
        `Cluster Settings for organization are updated on cluster ${clusterName}`,
      );
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  const renderContent = () => {
    if (initializing) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <div className="mt-4 flex flex-col gap-6">
        <Field.Select
          {...register('role')}
          label="Default Cluster Role"
          note="The default role that will be assigned to each new user added to the cluster"
          options={roleOptions}
          error={errors.role}
        />
        <Field.Input
          {...register('jobs')}
          label="Default Total Running Jobs Quota"
          type="number"
          note="The default amount of total running jobs that will be given to each new user added to the cluster. Empty value means unlimited default total running jobs quota"
          error={errors.jobs}
        />
        <div className="flex justify-end">
          <Button type="submit" loading={loading} className="px-10">
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('General', 'Cluster Settings', '%c')} />
      <form
        className="relative max-w-[780px] rounded-xl bg-white p-10"
        onSubmit={handleSettingsSubmit}
      >
        <h6 className="text-h6">General</h6>
        <div className="relative">{renderContent()}</div>
      </form>
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { captureMessage } from '@sentry/react';

import { UserOrganization } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { toast } from '@features';
import * as service from '@services';
import {
  OrganizationCreditsState,
  setOrganizationCredits,
  setOrganizationCreditsLoading,
  setUserOrganizationCredits,
} from '@slices';
import { configSelector, contextNamesSelector, userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { getOrganizationName } from '@utils';

export const useOrganizationCredits = () => {
  const dispatch = useDispatch();
  const { organizationName } = useSelector(contextNamesSelector);
  const { adminUrl } = useSelector(configSelector);
  const { username } = useSelector(userSelector);

  const [userOrganizations, setUserOrganizations] = useState<
    UserOrganization[]
  >([]);

  const setLoading = useCallback(
    (loading: OrganizationCreditsState['loading']) => {
      dispatch(setOrganizationCreditsLoading(loading));
    },
    [dispatch],
  );

  const setCredits = useCallback(
    (
      type: 'user' | 'organization',
      credits?:
        | OrganizationCreditsState['organizationCredits']
        | OrganizationCreditsState['userOrganizationCredits'],
    ) => {
      const method =
        type === 'user' ? setUserOrganizationCredits : setOrganizationCredits;

      dispatch(method(credits));
    },
    [dispatch],
  );

  /**
   * Update user organization credits on every time
   * when organization is changed
   */
  useEffect(() => {
    if (organizationName === NO_ORGANIZATION) {
      return;
    }

    const userOrganization = userOrganizations.find(
      ({ orgName }) => orgName === organizationName,
    );

    if (userOrganization) {
      setCredits('user', userOrganization.balance.credits);
    }
  }, [organizationName, userOrganizations, setCredits]);

  useEffect(() => {
    const run = async () => {
      try {
        const { orgs } = await service.getUserOrganizationsCredits({
          adminUrl,
          username,
        });

        setUserOrganizations(orgs);
      } catch (error) {
        captureMessage(`Could not fetch ${username} user organizations`);
        toast.error('Could not fetch user organizations');
      }
    };

    if (username) {
      run();
    }
  }, [adminUrl, username]);

  useEffect(() => {
    const run = async () => {
      if (organizationName === NO_ORGANIZATION) {
        /**
         * Reset organization credits
         */
        setCredits('organization');

        return;
      }

      try {
        setLoading(true);

        const {
          balance: { credits: organizationCredits },
        } = await service.getOrganizationSettings({
          adminUrl,
          organizationName,
        });

        setCredits('organization', organizationCredits);

        setLoading(false);
      } catch (error) {
        captureMessage(
          `Cannot parse organization balance for ${getOrganizationName(
            organizationName,
          )} organization`,
        );
      }
    };

    run();
  }, [adminUrl, organizationName, setLoading, setCredits]);
};

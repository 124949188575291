export const pick = <
  T extends Record<string, unknown>,
  K extends Extract<keyof T, string>,
>(
  target: T,
  ...properties: K[]
): Pick<T, K> => {
  return properties.reduce(
    (acc, property) => {
      if (property in target) {
        acc[property] = target[property];
      }

      return acc;
    },
    {} as Pick<T, K>,
  );
};

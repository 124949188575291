import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import {
  faArrowRotateRight,
  faArrowsRotate,
  faCircleXmark,
  faDownload,
  faExternalLink,
} from '@fortawesome/pro-regular-svg-icons';

import { AsyncFunction, Job as JobType, Payload } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { toast } from '@features';
import { createJob, killRunningJob } from '@services';
import { userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, invariant, path, pick, toastifyResponseError } from '@utils';

import { Button, Icon, Link, Modal, Render } from '@components';
import { SaveJobImageModal } from '@components/Modals';
import { Job } from '@components/Ui';

type Loading = {
  isKilling: boolean;
  isRefreshing: boolean;
  isRerunning: boolean;
};

type Props = {
  isApp?: boolean;
  job: JobType.Model | null;
  getJob: AsyncFunction;
};

export const JobDetailsHandleCenter = ({
  isApp = false,
  job,
  getJob,
}: Props) => {
  const { username } = useSelector(userSelector);

  const navigate = useNavigate();

  const [loading, setLoading] = useImmer<Loading>({
    isKilling: false,
    isRefreshing: false,
    isRerunning: false,
  });

  const {
    id: jobId,
    owner,
    status,
    httpUrl,
    httpUrlNamed,
    history,
  } = as.o<JobType.Model>(job);
  const isRunningJob = ['pending', 'running'].includes(status);
  const isOpenLinkDisabled = !httpUrlNamed && !httpUrl;
  const isJobRerunDisabled =
    owner !== username || !username || isRunningJob || !job;

  const handleLoading = useCallback(
    (name: keyof Loading, value: boolean) => {
      setLoading((state) => {
        state[name] = value;
      });
    },
    [setLoading],
  );

  const handleJobRefresh = useCallback(async () => {
    try {
      handleLoading('isRefreshing', true);

      await getJob();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      handleLoading('isRefreshing', false);
    }
  }, [handleLoading, getJob]);

  const handleJobKill = async () => {
    try {
      handleLoading('isKilling', true);

      await killRunningJob(jobId);
      await getJob();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      handleLoading('isKilling', false);
    }
  };

  const handleJobRerun = async () => {
    try {
      handleLoading('isRerunning', true);

      invariant(job);

      const typeName = isApp ? 'App' : 'Job';
      const route = isApp ? path.app : path.job;

      const {
        orgName: organizationName = NO_ORGANIZATION,
        maxRunTimeMinutes: lifespanMinutes,
        privileged: privilegedMode,
        ...jobPick
      } = pick(
        job,
        'clusterName',
        'projectName',
        'presetName',
        'name',
        'tags',
        'description',
        'orgName',
        'restartPolicy',
        'scheduleTimeout',
        'maxRunTimeMinutes',
        'privileged',
      );
      const {
        /**
         * todo: fix terminal error
         */
        // @ts-ignore
        http: { port: httpPort, requiresAuth: httpAuth } = {},
        tty: ttyAllocation,
        volumes,
        diskVolumes,
        secretVolumes,
        ...jobContainerPick
      } = pick(
        job.container,
        'command',
        'entrypoint',
        'env',
        'image',
        'diskVolumes',
        'secretEnv',
        'secretVolumes',
        'http',
        'tty',
        'diskVolumes',
        'volumes',
        'secretVolumes',
      );

      const jobVolumes: Payload.CreateJob['jobVolumes'] = {
        secretVolumes,
        volumes,
        diskVolumes,
      };
      const payload = {
        organizationName,
        httpPort,
        httpAuth,
        ttyAllocation,
        lifespanMinutes,
        privilegedMode,
        jobVolumes,
        ...jobPick,
        ...jobContainerPick,
      };

      const { id: jobId } = await createJob(payload);
      await getJob();

      toast.success(`${typeName} ${job.name ?? ''} rerun`);

      navigate(route(jobId));
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      handleLoading('isRerunning', false);
    }
  };

  return (
    <div className="mb-8 flex justify-end gap-2">
      <Job.Status
        {...history}
        variant="details"
        className="mr-auto gap-2 !bg-white px-5 py-1.5 text-body"
      />
      <Render if={!isApp}>
        <Link
          blank
          external
          theme
          to={httpUrlNamed ?? httpUrl}
          disabled={isOpenLinkDisabled}
        >
          <Button
            variant="rebecca"
            disabled={isOpenLinkDisabled}
            className="flex items-center gap-1.5 px-6"
          >
            <Icon icon={faExternalLink} className="text-[20px]" />
            Open
          </Button>
        </Link>
        <Modal
          disabled={status !== 'running'}
          content={<SaveJobImageModal jobId={jobId} />}
        >
          <Button
            variant="rebecca"
            disabled={status !== 'running'}
            className="flex items-center gap-1.5 capitalize"
          >
            <Icon icon={faDownload} className="text-[20px]" />
            Save image
          </Button>
        </Modal>
      </Render>
      <Button
        variant="rebecca"
        loading={loading.isRefreshing}
        disabled={!job}
        onClick={handleJobRefresh}
        className="flex items-center gap-1.5"
      >
        <Icon icon={faArrowsRotate} className="text-[20px]" />
        Refresh
      </Button>
      <Button
        variant="rebecca"
        loading={loading.isRerunning}
        disabled={isJobRerunDisabled}
        className="flex items-center gap-1.5"
        onClick={handleJobRerun}
      >
        <Icon icon={faArrowRotateRight} className="text-[20px]" />
        {isApp ? 'Reinstall' : 'Rerun'}
      </Button>
      <Button
        variant="rebecca"
        loading={loading.isKilling}
        disabled={!isRunningJob}
        onClick={handleJobKill}
        className="flex items-center gap-1.5 text-error"
      >
        <Icon icon={faCircleXmark} className="text-[20px]" />
        {isApp ? 'Uninstall' : 'Kill'}
      </Button>
    </div>
  );
};

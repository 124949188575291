import { Mode, Payload, VolumeType } from '@typings';
import { toast } from '@features';
import { path as pathResolver } from '@utils';

namespace Volume {
  export type Input = {
    path: string;
    prefix?: string;
    type: VolumeType;
    resource: string;
    mode: Mode;
  }[];

  export type ReturnType = {
    storageVolumes: Payload.StorageVolume[];
    diskVolumes: Payload.DiskVolume[];
    secretVolumes: Payload.SecretVolume[];
  };
}

/**
 * Adds `pattern:/` prefix because `path.create()` joins elements by "/"
 */
const uri = {
  storage: (src: string): { src_storage_uri: string } => ({
    src_storage_uri: `storage:/${src}`,
  }),
  disk: (src: string): { src_disk_uri: string } => ({
    src_disk_uri: `disk:/${src}`,
  }),
  secret: (src: string): { src_secret_uri: string } => ({
    src_secret_uri: `secret:/${src}`,
  }),
};

export const getFormattedJobVolumes = (
  volumes: Volume.Input,
  clusterName?: string,
) => {
  return volumes.reduce<Volume.ReturnType>(
    (acc, { path, prefix = '', mode, resource, type }) => {
      /**
       * Skips empty volumes
       */
      if (!resource && !path) {
        return acc;
      }

      if (!resource || !path) {
        toast.warning(
          'One of the volumes was skipped because "path" or "resource" were missed',
        );

        return acc;
      }

      const src = pathResolver.create(clusterName, prefix, resource);
      const payload = {
        dst_path: path,
        read_only: mode !== Mode.ReadWrite,
      };

      if (type === VolumeType.Storage) {
        acc.storageVolumes.push({
          ...payload,
          ...uri.storage(src),
        });
      } else if (type === VolumeType.Disk) {
        acc.diskVolumes.push({
          ...payload,
          ...uri.disk(src),
        });
      } else if (type === VolumeType.Secret) {
        acc.secretVolumes.push({
          ...uri.secret(src),
          dst_path: path,
        });
      }

      return acc;
    },
    {
      storageVolumes: [],
      diskVolumes: [],
      secretVolumes: [],
    },
  );
};

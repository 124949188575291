import { combineReducers } from 'redux';

import organizationCreditsReducer from './slices/cache/organizationCredits.slice';
import organizationInvitesReducer from './slices/cache/organizationInvites.slice';
import permissionsReducer from './slices/cache/permissions.slice';
import userClustersReducer from './slices/cache/userClusters.slice';
import viewTypeReducer from './slices/cache/viewType.slice';
import configReducer from './slices/config.slice';
import contextReducer from './slices/context.slice';
import userReducer from './slices/user.slice';

export const rootReducer = combineReducers({
  config: configReducer,
  context: contextReducer,
  user: userReducer,
  cache: combineReducers({
    organizationCredits: organizationCreditsReducer,
    organizationInvites: organizationInvitesReducer,
    permissions: permissionsReducer,
    userClusters: userClustersReducer,
    viewType: viewTypeReducer,
  }),
});

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { DEDICATED_APP_NAME, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  getInstallingAppImage,
  invariant,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  JobConstructorNavigator,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { AppConstructorNavigationProvider } from '@components/Providers';
import { EmptyContent } from '@components/Ui';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  presetName: z.string().min(1),
  name: z.string(),
});

export const FocusConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;

  const appName = DEDICATED_APP_NAME.FOCUS;
  const {
    name,
    title,
    tags = [],
    image,
  } = dedicatedApps.find(({ name }) => name === appName)!;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { powerlessPresetName } = usePowerlessResourcePresetName();

  const handleAppInstall = handleSubmit(async ({ name, presetName }) => {
    try {
      setLoading(true);

      const formattedName = name
        ? formatModelName(name)
        : `${appName}-${customAlphabet(lowercase, 8)()}`;

      const appCommand = new AppCommand();

      const command = appCommand
        .construct(
          `install https://github.com/neuro-inc/Fooocus ${appName} ${formattedName}`,
        )
        .set('preset_name', presetName)
        .compose({
          settingsParser: (key, value) => `--${key}=${value}`,
        });

      invariant(clusterName);
      invariant(projectName);

      await createJob({
        organizationName,
        clusterName,
        projectName,
        command,
        presetName: powerlessPresetName,
        image: getInstallingAppImage(image),
        name: `${formattedName}-install`,
        passConfig: true,
        tags: [...staticTags, ...tags],
      });

      toast.success(`Installing ${title} App`);

      navigate(PATH.APPS, { replace: true });
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <AppConstructorNavigationProvider>
          <JobConstructorNavigator />
          <FormProvider {...methods}>
            <form
              className="flex flex-1 items-start justify-center"
              onSubmit={handleAppInstall}
            >
              <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
                <JobConstructorSection name="resources">
                  <JobPresetField
                    error={errors.presetName}
                    note="Requiring minimal GPU memory (4GB)"
                  />
                </JobConstructorSection>
                <JobConstructorSection name="metadata">
                  <Field.Input
                    {...register('name')}
                    label="Name"
                    className="w-full"
                    note="App name"
                    error={errors.name}
                  />
                </JobConstructorSection>
                <Modal.Footer sticky className="-mt-8 px-0">
                  <Button
                    type="submit"
                    loading={loading}
                    className="px-10 capitalize"
                  >
                    Install app
                  </Button>
                </Modal.Footer>
              </Theme.Container>
            </form>
          </FormProvider>
        </AppConstructorNavigationProvider>
      </Layout.Content>
    </Layout>
  );
};

import { Params, UserInfoCluster, UserOrganization } from '@typings';
import { API_ADMIN } from '@constants';
import { axios } from '@features';
import { path } from '@utils';

export const agreeUserTerms = ({ username, email }: Params.AgreeUserTerms) => {
  return axios.put(`${API_ADMIN}/users/${username}/profile`, {
    terms_accepted: true,
    name: username,
    email,
  });
};

export const getUserClusters = ({
  adminUrl,
  username,
}: Params.GetUserClusters) => {
  return axios.get<
    never,
    { termsAccepted?: boolean; clusters: UserInfoCluster[] }
  >(`${adminUrl}/users/${username}`, {
    params: { include: 'clusters' },
  });
};

export const getUserOrganizationsCredits = ({
  adminUrl,
  username,
}: Params.GetUserOrganizationCredits) =>
  axios.get<never, { orgs: UserOrganization[] }>(
    path.create(adminUrl, 'users', username),
    { params: { include: 'orgs' } },
  );

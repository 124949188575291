import { faShareNodes } from '@fortawesome/pro-thin-svg-icons';

import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { getDashboardLinkParams } from '@utils';

import { Helmet } from '@components';
import { DashboardReportLink, EmptyContent } from '@components/Ui';

export const OrganizationSettingsReportsOutlet = () => {
  const { organizationName } = useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  const { makeTitle } = useHelmetTitle();

  const getParams = (source: string) => {
    const params = getDashboardLinkParams({
      source,
      titlePattern: 'Organization {{source}} dashboard',
    });

    return {
      ...params,
      prefix: 'org',
      params: {
        refresh: '1m',
        /**
         * `organizationName` always exist, because user
         * cannot to get this page for no organization
         */
        'var-org_name': organizationName!,
      },
    };
  };

  const renderContent = () => {
    if (cluster) {
      return (
        <>
          <DashboardReportLink {...getParams('jobs')} />
          <DashboardReportLink {...getParams('credits')} />
        </>
      );
    }

    return (
      <EmptyContent
        icon={faShareNodes}
        title="Missing Cluster"
        text="You have no specified cluster for reports"
      />
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Reports', 'Organization Settings', '%o')} />
      <div className="flex flex-wrap gap-10">{renderContent()}</div>
    </>
  );
};

import { Organization } from '@typings';
import { path } from '@utils';

type Params = {
  organizationName?: Organization.Name;
  clusterName?: string;
  projectName?: string;
  token?: string;
};

export const makeHuggingFaceSecretPath = ({
  organizationName,
  clusterName,
  projectName,
  token,
}: Params) => {
  const secretPath = path.create(
    clusterName,
    organizationName,
    projectName,
    token,
    { prefix: '' },
  );

  if (!token) {
    return null;
  }

  return token ? `secret://${secretPath}` : null;
};

export const makeHuggingFaceSecretEnv = (
  token: string | null | undefined,
  propertyName: string = 'HUGGING_FACE_HUB_TOKEN',
) => (token ? { [propertyName]: token } : undefined);

import { createSelector } from 'reselect';

import { State, ViewType } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { isNumber } from '@utils';

import { contextNamesSelector } from './context.selectors';

export const cacheSelector = (state: State) => state.cache;

export const permissionSelector = createSelector(
  cacheSelector,
  ({ permissions }) => permissions,
);

export const userClustersSelector = createSelector(
  cacheSelector,
  ({ userClusters }) => userClusters,
);

export const viewTypeSelector = createSelector(
  cacheSelector,
  ({ viewType }) => {
    const isGridView = viewType === ViewType.Grid;
    const isListView = viewType === ViewType.List;

    return { viewType, isGridView, isListView };
  },
);

export const organizationsInvitesSelector = createSelector(
  cacheSelector,
  ({ organizationInvites }) => organizationInvites,
);

export const organizationCreditsSelector = createSelector(
  cacheSelector,
  contextNamesSelector,
  (
    {
      organizationCredits: {
        organizationCredits,
        userOrganizationCredits,
        loading: organizationCreditsLoading,
      },
    },
    { organizationName },
  ) => {
    const isNoOrganization = organizationName === NO_ORGANIZATION;
    const isOrganizationBalanceUnlimited =
      !isNoOrganization && !isNumber(organizationCredits);
    const isUserOrganizationBalanceUnlimited = !isNumber(
      userOrganizationCredits,
    );

    return {
      organizationCredits,
      organizationCreditsLoading,
      userOrganizationCredits,
      isOrganizationBalanceUnlimited,
      isUserOrganizationBalanceUnlimited,
    };
  },
);

import { isBoolean, isNumber, isString } from './typeof';

type Value = string | number | boolean;

type SettingsParser = (key: string, value: Value) => string;

type ComposerOptions = {
  settingsParser?: SettingsParser;
};

export class AppCommand {
  private command: string = '';

  private args: string[] = [];

  private settings: { [key: string]: Value } = {};

  public construct(command: string): AppCommand {
    this.command = command;

    return this;
  }

  public arg(value: string): AppCommand {
    if (value) {
      this.args.push(value);
    }

    return this;
  }

  public set(
    key: string,
    value: Value | null | undefined,
    state?: boolean,
  ): AppCommand {
    /**
     * Some manual set state
     *
     * @example
     * .set('property', 'value'); // set will be added
     *
     * @example
     * .set('property', 'value', true); // set will be added
     *
     * @example
     * .set('property', 'value', false); // set will be ignored
     */
    if (isBoolean(state) && !state) {
      return this;
    }

    /**
     * Skip invalid or unexpected value to adding as part of settings
     */
    const isValueValid =
      (isString(value) && !!value) || isNumber(value) || isBoolean(value);

    if (isValueValid) {
      this.settings[key] = value;
    }

    return this;
  }

  private parseSettings(
    settingsParser: SettingsParser = (key, value) => `--set "${key}=${value}"`,
  ): string {
    return Object.entries(this.settings).reduce(
      (settings, [key, value]) => `${settings} ${settingsParser(key, value)}`,
      '',
    );
  }

  private parseArgs(): string {
    return this.args.reduce(
      (args, value, index) =>
        `${args} --set "serverExtraArgs[${index}]=${value}"`,
      '',
    );
  }

  public setHost(clusterName: string, appName: string) {
    this.set(
      'ingress.hosts[0].host',
      `${appName}.apps.${clusterName}.org.neu.ro`,
    );

    return this;
  }

  public compose({ settingsParser }: ComposerOptions = {}): string {
    return `${this.command} ${this.parseSettings(
      settingsParser,
    )} ${this.parseArgs()}`;
  }
}

import { useState } from 'react';
import * as sentry from '@sentry/react';
import {
  faCheck,
  faGear,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleNodes } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Cluster, UserInfoCluster } from '@typings';
import { NO_ORGANIZATION, PATH } from '@constants';
import { toast } from '@features';
import { setContext } from '@slices';
import {
  configSelector,
  contextNamesSelector,
  userClustersSelector,
} from '@selectors';
import { useContextSearchParams, useDispatch, useSelector } from '@hooks';
import { as } from '@utils';

import { Button, Icon, Link, Popover, Render } from '@components';

import { UserPanelButton } from './UserPanelButton';

export const UserPanelClusters = () => {
  const dispatch = useDispatch();
  const { clusters: configClusters = [], projects = [] } =
    useSelector(configSelector);
  const userClusters = useSelector(userClustersSelector);
  const { organizationName, clusterName } = useSelector(contextNamesSelector);

  const { contextSearchParams } = useContextSearchParams({ project: false });

  const [popoverOpen, setPopoverOpen] = useState(false);

  const organizationClusters = userClusters.filter(
    ({ orgName }) => as(orgName, NO_ORGANIZATION) === organizationName,
  );
  const clusterList = organizationClusters.filter(
    ({ clusterName: userClusterName }) => userClusterName !== clusterName,
  );

  const getClusterByName = (clusterName?: string) => {
    return configClusters.find(({ name }) => name === clusterName) ?? null;
  };

  const { location: currentClusterLocation, logoUrl } = as.c(
    getClusterByName(clusterName),
  );

  const hidePopover = () => setPopoverOpen(false);

  const handleClusterChange = ({ clusterName }: UserInfoCluster) => {
    const cluster = getClusterByName(clusterName);

    if (!cluster) {
      sentry.captureMessage(
        `Cluster ${clusterName} was not found in user cluster`,
      );
      toast.error('Something went wrong');

      return;
    }

    const project =
      projects.find(({ orgName, clusterName: clusterNameContext }) => {
        return (
          as(orgName, NO_ORGANIZATION) === organizationName &&
          clusterNameContext === clusterName
        );
      }) ?? null;

    dispatch(setContext({ cluster, project }));

    hidePopover();
  };

  const renderClusterLogo = (logoUrl: Cluster.Model['logoUrl']) => {
    const className = 'h-10 w-10 rounded-md';

    if (logoUrl) {
      return (
        <img
          src={logoUrl}
          alt={clusterName}
          className={clsx('object-cover', className)}
        />
      );
    }

    return (
      <Icon
        icon={faCircleNodes}
        className={clsx('bg-secondary-dark text-[20px] text-white', className)}
      />
    );
  };

  const makeCluster = (cluster: UserInfoCluster) => {
    const { clusterName } = cluster;
    const { location: clusterFootnote, logoUrl } = as.c(
      getClusterByName(clusterName),
    );

    return (
      <Button
        theme
        key={clusterName}
        className="flex w-full items-center gap-3 rounded-lg p-2 transition-colors hover:bg-neural-01"
        onClick={() => {
          handleClusterChange(cluster);
          hidePopover();
        }}
      >
        {renderClusterLogo(logoUrl)}
        <div className="flex-1 text-left">
          <p className="word-break-case">{clusterName}</p>
          <Render>
            <p className="text-body-small text-neural-04">{clusterFootnote}</p>
          </Render>
        </div>
      </Button>
    );
  };

  if (!organizationClusters.length) {
    return (
      <>
        <UserPanelButton
          disabled
          className="flex items-center gap-2 text-white opacity-40"
        >
          <Icon
            icon={faCircleNodes}
            className="h-10 w-10 rounded-md bg-white/50"
          />
          <div className="text-left">
            <p className="text-body-small">Cluster</p>
            <p>No clusters are available</p>
          </div>
        </UserPanelButton>
        <div className="w-px bg-neural-01 " />
      </>
    );
  }

  return (
    <Popover
      open={popoverOpen}
      showArrow={false}
      side="bottom"
      align="end"
      className="w-[320px] p-0"
      onOpenChange={setPopoverOpen}
    >
      <UserPanelButton
        slot="trigger"
        className="flex w-[200px] items-center gap-2 text-white"
      >
        {renderClusterLogo(logoUrl)}
        <div className="min-w-0 flex-1 text-left text-white">
          <p className="text-body-small text-neural-03">Cluster</p>
          <p className="truncate">{clusterName}</p>
        </div>
      </UserPanelButton>
      <div className="w-full pb-5">
        <p className="p-5 text-caption uppercase text-neural-04">Clusters</p>
        <div className="bg-neural-01 p-5">
          <div className="flex items-center gap-3">
            {renderClusterLogo(logoUrl)}
            <div className="flex-1 text-left">
              <p className="word-break-case">{clusterName}</p>
              <Render if={currentClusterLocation}>
                <p className="text-body-small text-neural-04">
                  {currentClusterLocation}
                </p>
              </Render>
            </div>
            <Icon icon={faCheck} className="text-[20px] text-secondary-dark" />
          </div>
          <Link
            theme
            to={`${PATH.CLUSTER_SETTINGS}?${contextSearchParams}`}
            className="group mt-2 flex items-center gap-2 py-3 transition-colors hover:bg-white/10"
          >
            <Icon
              icon={faGear}
              className="text-[20px] transition-colors group-hover:text-secondary-dark"
            />
            Settings
          </Link>
          <Link
            theme
            to={`${PATH.CLUSTER_SETTINGS_USERS}?${contextSearchParams}`}
            className="group flex items-center gap-2 py-3 transition-colors hover:bg-white/10"
          >
            <Icon
              icon={faUserGroup}
              className="text-[20px] transition-colors group-hover:text-secondary-dark"
            />
            People
          </Link>
        </div>
        <Render if={clusterList}>
          <div className="mt-2 flex max-h-[320px] flex-col gap-2 overflow-auto px-3">
            {clusterList.map(makeCluster)}
          </div>
        </Render>
      </div>
    </Popover>
  );
};

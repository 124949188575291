/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useState } from 'react';
import {
  faEllipsisVertical,
  faPaperPlaneTop,
  faTrashXmark,
  faUserMinus,
  faUserPen,
} from '@fortawesome/pro-solid-svg-icons';

import { AsyncFunction, ClusterSettings, OrganizationInvite } from '@typings';
import { toast } from '@features';
import { inviteUserToOrganization } from '@services';
import { contextNamesSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';
import { as, invariant, noop, toastifyResponseError } from '@utils';

import { Icon, Modal, Popover, Render } from '@components';
import {
  OrganizationEditUserModal,
  OrganizationRemoveUserInviteModal,
  OrganizationRemoveUserModal,
} from '@components/Organization';

import { OrganizationUserAction as Action } from './OrganizationUserAction';

type Props = {
  invite?: OrganizationInvite.Interface;
  user?: ClusterSettings.User;
  getUsers: AsyncFunction;
};

export const OrganizationUserActions = ({
  invite,
  user,
  getUsers = noop,
}: Props) => {
  const { organizationName } = useSelector(contextNamesSelector);

  const { isOrganizationManager } = useTenantRole();

  const [openPopover, setOpenPopover] = useState(false);

  const {
    id: inviteId,
    email,
    role: inviteRole,
  } = as.o<OrganizationInvite.Interface>(invite);
  const {
    role: userRole,
    userName: username,
    balance,
  } = as.o<ClusterSettings.User>(user);

  const isInvite = !!inviteId;
  const isUser = !!username;

  const hidePopover = useCallback(() => {
    setOpenPopover(false);
  }, []);

  /**
   * Common users fetching with popover closing
   */
  const fetchUsers = useCallback(async () => {
    try {
      hidePopover();

      await getUsers();
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  }, [getUsers, hidePopover]);

  const handleInviteResend = async () => {
    try {
      invariant(organizationName);

      await inviteUserToOrganization({
        email,
        role: inviteRole,
        organizationName,
      });
      await fetchUsers();

      toast.success(`We have re-sent the invitation to ${email}`);
    } catch (error) {
      toastifyResponseError(error);
    }
  };

  return (
    <Popover
      showArrow={false}
      side="bottom"
      align="end"
      className="px-0 py-2"
      open={openPopover}
      onOpenChange={setOpenPopover}
    >
      <Icon slot="trigger" icon={faEllipsisVertical} className="text-[20px]" />
      <div>
        <Render if={isInvite}>
          <Action
            disabled={!isOrganizationManager}
            icon={faPaperPlaneTop}
            onClick={handleInviteResend}
          >
            Resend invite
          </Action>
          <Modal
            onClose={hidePopover}
            content={
              <OrganizationRemoveUserInviteModal
                inviteId={inviteId}
                email={email}
                getUsers={fetchUsers}
              />
            }
          >
            <Action
              attention
              disabled={!isOrganizationManager}
              icon={faUserMinus}
            >
              Remove invite
            </Action>
          </Modal>
        </Render>
        <Render if={isUser}>
          <Modal
            onClose={hidePopover}
            content={
              <OrganizationEditUserModal
                credits={balance?.credits}
                username={username}
                role={userRole}
                getUsers={fetchUsers}
              />
            }
          >
            <Action disabled={!isOrganizationManager} icon={faUserPen}>
              Edit user
            </Action>
          </Modal>
          <Modal
            onClose={hidePopover}
            content={
              <OrganizationRemoveUserModal
                username={username}
                getUsers={fetchUsers}
              />
            }
          >
            <Action
              attention
              disabled={!isOrganizationManager}
              icon={faTrashXmark}
            >
              Remove user
            </Action>
          </Modal>
        </Render>
      </div>
    </Popover>
  );
};

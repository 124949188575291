import { NumericString } from '@typings';
import { isString } from '@utils';

export const truncateDecimals = (value: NumericString | undefined) => {
  if (!isString(value) || !value.includes('.')) {
    return value;
  }

  const [integer, decimal] = value.split('.');

  return `${integer}.${decimal.substring(0, 2)}` as NumericString;
};

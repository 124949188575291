import { useAuth0 } from '@auth0/auth0-react';

import { SUPPORT_EMAIL } from '@constants';
import { useHostname } from '@hooks/useHostname';

import { Button, Helmet, Link, Theme } from '@components';
import background from '@images/auth-background.png';

export const AuthPage = () => {
  const { loginWithRedirect } = useAuth0();
  const { title } = useHostname();

  const handleRegister = () =>
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
    });

  return (
    <div className="relative mx-auto flex h-full w-full flex-1 flex-col bg-background px-16 pb-6 pt-10 font-inter">
      <Helmet
        title="Log In / Sign Up"
        description="Access your account securely and effortlessly by authenticating your identity"
      />
      <img
        src={background}
        alt="background"
        className="absolute inset-x-0 bottom-0 min-w-full"
      />
      <div className="flex items-center justify-between">
        <Theme.Logo className="h-10 w-auto text-black" />
      </div>
      <div className="relative flex flex-1 items-center justify-center">
        <Theme.Container className="flex w-full max-w-[500px] flex-col items-center gap-6 px-6 py-12">
          <h2 className="text-center text-h4">
            Welcome to <span className="inline-block">{title}</span>!
          </h2>
          <Button
            className="px-16 capitalize"
            onClick={() => loginWithRedirect()}
          >
            Log in
          </Button>
          <p className="-mt-2 text-neural-04">
            Don&apos;t have an account?
            <Button
              variant="ghost-primary"
              className="px-2"
              onClick={handleRegister}
            >
              Sign up
            </Button>
          </p>
        </Theme.Container>
      </div>

      <p className="relative text-center text-footnote text-neural-02">
        Need assistance? Contact our support team at&nbsp;
        <Link
          theme
          external
          className="text-primary-subtle underline transition-colors hover:text-primary-light"
          to={`mailto:${SUPPORT_EMAIL}`}
        >
          {SUPPORT_EMAIL}
        </Link>
        &nbsp;anytime
      </p>
    </div>
  );
};

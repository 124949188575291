import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { DEDICATED_APP_NAME, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  getInstallingAppImage,
  invariant,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  JobConstructorNavigator,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { AppConstructorNavigationProvider } from '@components/Providers';
import { EmptyContent } from '@components/Ui';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  apiReplicas: z.string(),
  workerReplicas: z.string(),
  apiPresetName: z.string().min(1),
  workerPresetName: z.string().min(1),
  proxyPresetName: z.string().min(1),
  webReplicas: z.string(),
  webPresetName: z.string().min(1),
  redisMasterPresetName: z.string().min(1),
  externalPostgresPlatformAppName: z.string().min(1),
  externalPostgresUsername: z.string(),
  externalPostgresDbName: z.string(),
  externalPgvectorPlatformAppName: z.string().min(1),
  externalPgvectorUsername: z.string(),
  externalPgvectorDbName: z.string(),
  ingressEnabled: z.boolean().optional(),
  name: z.string(),
});

export const DifyConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({ resolver: zodResolver(schema) });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;

  const appName = DEDICATED_APP_NAME.DIFY;
  const {
    name,
    title,
    tags = [],
    image,
  } = dedicatedApps.find(({ name }) => name === appName)!;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { powerlessPresetName } = usePowerlessResourcePresetName({ appName });

  const handleAppInstall = handleSubmit(
    async ({
      name,
      apiReplicas,
      workerReplicas,
      apiPresetName,
      workerPresetName,
      proxyPresetName,
      webReplicas,
      webPresetName,
      redisMasterPresetName,
      externalPostgresDbName,
      externalPgvectorDbName,
      externalPgvectorPlatformAppName,
      externalPgvectorUsername,
      externalPostgresPlatformAppName,
      externalPostgresUsername,
      ingressEnabled,
    }) => {
      try {
        setLoading(true);

        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;

        const appCommand = new AppCommand();

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/dify-helm ${appName} ${formattedName} charts/dify --timeout=600s --dependency-update`,
          )
          .set('ingress.enabled', ingressEnabled)
          .set('api.replicas', apiReplicas)
          .set('api.preset_name', apiPresetName)
          .set('worker.replicas', workerReplicas)
          .set('worker.preset_name', workerPresetName)
          .set('proxy.preset_name', proxyPresetName)
          .set('web.replicas', webReplicas)
          .set('web.preset_name', webPresetName)
          .set('redis.master.preset_name', redisMasterPresetName)
          .set(
            'externalPostgres.platformAppName',
            externalPostgresPlatformAppName,
          )
          .set('externalPostgres.username', externalPostgresUsername)
          .set('externalPostgres.dbName', externalPostgresDbName)
          .set(
            'externalPgvector.platformAppName',
            externalPgvectorPlatformAppName,
          )
          .set('externalPgvector.username', externalPgvectorUsername)
          .set('externalPgvector.dbName', externalPgvectorDbName)
          .compose();

        invariant(projectName);
        invariant(clusterName);

        await createJob({
          organizationName,
          clusterName,
          projectName,
          command,
          presetName: powerlessPresetName,
          image: getInstallingAppImage(image),
          name: `${formattedName}-install`,
          passConfig: true,
          tags: [...staticTags, ...tags],
        });

        toast.success(`Installing ${title} App`);

        navigate(PATH.APPS, { replace: true });
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <AppConstructorNavigationProvider>
          <JobConstructorNavigator />
          <FormProvider {...methods}>
            <form
              className="flex flex-1 justify-center"
              onSubmit={handleAppInstall}
            >
              <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
                <JobConstructorSection name="worker">
                  <JobPresetField
                    name="redisMasterPresetName"
                    label="Redis Master Preset"
                    note="CPU / Memory preset for Redis master instance"
                    error={errors.redisMasterPresetName}
                  />
                  <JobPresetField
                    name="workerPresetName"
                    label="Worker Preset"
                    note="CPU / Memory preset for worker service"
                    error={errors.workerPresetName}
                  />
                  <Field.Input
                    {...register('workerReplicas')}
                    label="Worker Replicas"
                    type="number"
                    inputMode="numeric"
                    note="Number of worker service replicas"
                    error={errors.workerReplicas}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="api">
                  <JobPresetField
                    name="apiPresetName"
                    label="API Preset"
                    note="CPU / Memory preset for the API service"
                    error={errors.apiPresetName}
                  />
                  <Field.Input
                    {...register('apiReplicas')}
                    label="API Replicas"
                    type="number"
                    inputMode="numeric"
                    note="Number of API service replicas"
                    error={errors.apiReplicas}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="web">
                  <JobPresetField
                    name="proxyPresetName"
                    label="Proxy Preset"
                    note="CPU / Memory preset for the proxy service"
                    error={errors.proxyPresetName}
                  />
                  <JobPresetField
                    name="webPresetName"
                    label="Web Preset"
                    note="CPU / Memory preset for the web service"
                    error={errors.webPresetName}
                  />
                  <Field.Input
                    {...register('webReplicas')}
                    label="Web Replicas"
                    type="number"
                    inputMode="numeric"
                    note="Number of web service replicas"
                    error={errors.webReplicas}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="configuration">
                  <Field.Checkbox {...register('ingressEnabled')}>
                    Public ingress enabled
                  </Field.Checkbox>
                </JobConstructorSection>
                <JobConstructorSection name="postgresql">
                  <Field.Input
                    {...register('externalPostgresPlatformAppName')}
                    required
                    label="External PostgreSQL Platform Name"
                    className="w-full"
                    note="PGVector app name to integrate with the Dify app"
                    error={errors.externalPostgresPlatformAppName}
                  />
                  <Field.Input
                    {...register('externalPostgresUsername')}
                    label="External PostgreSQL Username"
                    className="w-full"
                    note="Username within the PGVector app. First available user used if not specified"
                    error={errors.externalPostgresUsername}
                  />
                  <Field.Input
                    {...register('externalPostgresDbName')}
                    label="External PostgreSQL DB Name"
                    className="w-full"
                    note="Database to use within the PGVector app. First available DB used if not specified"
                    error={errors.externalPostgresDbName}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="pgvector">
                  <Field.Input
                    {...register('externalPgvectorPlatformAppName')}
                    required
                    label="External PGVector Platform Name"
                    className="w-full"
                    note="PGVector app name for direct vector data integration. It can be the same external PostgreSQL app"
                    error={errors.externalPgvectorPlatformAppName}
                  />
                  <Field.Input
                    {...register('externalPgvectorUsername')}
                    label="External PGVector Username"
                    className="w-full"
                    note="Username for PGVector integration. First available user used if not specified"
                    error={errors.externalPgvectorUsername}
                  />
                  <Field.Input
                    {...register('externalPgvectorDbName')}
                    label="External PGVector DB Name"
                    className="w-full"
                    note="Database to use within PGVector for vector data storage"
                    error={errors.externalPgvectorDbName}
                  />
                </JobConstructorSection>

                <JobConstructorSection name="metadata">
                  <Field.Input
                    {...register('name')}
                    label="Name"
                    className="w-full"
                    note="App name"
                    error={errors.name}
                  />
                </JobConstructorSection>
                <Modal.Footer sticky className="-mt-10 px-0">
                  <Button
                    type="submit"
                    loading={loading}
                    className="px-10 capitalize"
                  >
                    Install app
                  </Button>
                </Modal.Footer>
              </Theme.Container>
            </form>
          </FormProvider>
        </AppConstructorNavigationProvider>
      </Layout.Content>
    </Layout>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrganizationCreditsState = {
  loading: boolean;
  /**
   * Credits balance is unlimited if `undefined`
   */
  organizationCredits?: `${number}`;
  userOrganizationCredits?: `${number}`;
};

const initialState: OrganizationCreditsState = {
  loading: false,
};

export const organizationCreditsSlice = createSlice({
  name: 'organizationCredits',
  initialState,
  reducers: {
    setOrganizationCreditsLoading: (
      state,
      action: PayloadAction<OrganizationCreditsState['loading']>,
    ) => {
      state.loading = action.payload;
    },
    setOrganizationCredits: (
      state,
      action: PayloadAction<OrganizationCreditsState['organizationCredits']>,
    ) => {
      state.organizationCredits = action.payload;
    },
    setUserOrganizationCredits: (
      state,
      action: PayloadAction<
        OrganizationCreditsState['userOrganizationCredits']
      >,
    ) => {
      state.userOrganizationCredits = action.payload;
    },
    resetOrganizationCredits: () => initialState,
  },
});

export const {
  setOrganizationCreditsLoading,
  setOrganizationCredits,
  setUserOrganizationCredits,
  resetOrganizationCredits,
} = organizationCreditsSlice.actions;

export default organizationCreditsSlice.reducer;
